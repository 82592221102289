
  import userController from '/home/builduser/work/e46fc5f0e91b8aa0/packages/members-area-notifications/src/components/OoiNotifications/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://460ff4620fa44cba8df530afde949785@sentry.wixpress.com/5803',
    id: 'undefined',
    projectName: 'members-area-notifications',
    teamName: 'ping',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/e46fc5f0e91b8aa0/packages/members-area-notifications/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.title":"Notifications","app.widget.notification-item.time-ago":"{time, date}","app.widget.notification-item.time-ago.relative":"{time, timeRelative}","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/e46fc5f0e91b8aa0/packages/members-area-notifications/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Wix Members Area Notifications",
    componentName: "OoiNotifications",
    appDefinitionId: "14f25924-5664-31b2-9568-f9c5ed98c9b1",
    componentId: "14f2595a-a352-3ff1-9b3c-4d21861fe58f",
    projectName: "members-area-notifications",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/e46fc5f0e91b8aa0/packages/members-area-notifications/src/components/OoiNotifications/controller.ts",
  };

  export const controller = _controller
  export default controller;
