import type { IHttpClient } from '@wix/fe-essentials/http-client';
import { queryViewerFeedNotifications } from '@wix/ambassador-notifications-members-feed-v1-members-viewer-feed-notification/http';
import {
  Channel,
  SortOrder,
} from '@wix/ambassador-notifications-members-feed-v1-members-viewer-feed-notification/types';

export const getNotifications = (httpClient: IHttpClient) => {
  return httpClient.request(
    queryViewerFeedNotifications({
      query: {
        filter: {
          channel: Channel.WEB,
        },
        sort: [
          {
            fieldName: 'sentDate',
            order: SortOrder.DESC,
          },
        ],
        cursorPaging: {
          limit: 50,
        },
      },
    }),
  );
};
