import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { getNotifications } from '../../apis/members-feed/members-feed';
import moment from 'moment';
import 'moment/min/locales';

const DEFAULT_DATE_FORMAT = 'MMM D';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const languages = flowAPI.translations.config.availableLanguages;
  const momentLocales: Record<string, string> = {
    ...languages?.reduce((obj, l) => Object.assign(obj, { [l]: l }), {}),
    tl: 'tl-ph',
    zh: 'zh-tw',
    no: 'nb',
  };
  const dateFormats: Record<string, string> = {
    ...languages?.reduce(
      (acc, value) => ({ ...acc, [value]: DEFAULT_DATE_FORMAT }),
      {},
    ),
    ko: 'MMM Do',
    ja: 'MMMDo',
  };
  flowAPI.translations.i18n.init({
    fallbackLng: 'en',
    keySeparator: '$',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        switch (format) {
          case 'timeRelative':
            return moment(value).locale(momentLocales[lng!]).fromNow();
          case 'date':
            return moment(value)
              .locale(momentLocales[lng!])
              .format(dateFormats[lng!]);
          default:
            return value;
        }
      },
    },
  });
  const { data: notificationsRes } = await getNotifications(flowAPI.httpClient);
  return {
    async pageReady() {
      controllerConfig.setProps({
        notificationsRes,
      });
    },
  };
};

export default createController;
